<template>
  <v-card :elevation="0">
    <v-card-title>Cập nhật thông tin đơn hàng</v-card-title>
    <div style="text-align: center">
      <v-switch class="px-5 pt-0 ma-0" label="Cập nhật hàng loạt" v-model="batchReceive" style="display: inline-block;" inset></v-switch>
    </div>
    <template v-if="!batchReceive">
      <v-row>
        <v-col cols="6" offset="3" class="pa-0">
          <v-text-field class="pa-0" ref="orderCode" v-model="search.code" dense label="Nhập Order Code hoặc Tracking Number"
                        outlined @keypress.enter="updateOrder()"></v-text-field>
        </v-col>
        <v-col v-if="showOrderInfo" class="pt-0">
          <v-btn color="success" @click="onClickEditOrder">Sửa đơn hàng</v-btn>
        </v-col>
      </v-row>
      <div class="ma-5">
        <DetailOrder v-if="showOrderInfo" :onClose="onClickDetail" :onClickPrintLable="onClickDetail" :selectedItem="order"/>
      </div>

      <v-dialog v-model="dialogShipExpress" persistent max-width="450px">
        <v-card>
          <v-card-title class="style-title">
            <span class="headline">Đơn hàng SHIP EXPRESS-DIRECT cần sửa lại giá cước vận chuyển</span>
            <v-spacer></v-spacer>
            <v-icon color="#fff">mdi-close</v-icon>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#E53935" dark>Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogUpdateOrder" :width="'80%'">
        <UpdateOrder :onUpdated="onOrderUpdated"
                     :selectedItem="order"
                     :onCancel="()=>{dialogUpdateOrder=false}"></UpdateOrder>
      </v-dialog>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="6" offset="3" class="pa-0">
          <v-file-input class="pa-0" ref="orderCode" v-model="batchFile" dense label="Nhập file excel chứa list order code hoặc tracking number"
                        outlined></v-file-input>
        </v-col>
        <v-col class="pt-0">
          <v-btn color="primary" v-if="batchFile != null" @click="updateOrderBatch()">Import</v-btn>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import DetailOrder from '@/admin/pages/ManageOrders/Detail'
import UpdateOrder from '@/admin/pages/ManageOrders/Update'

export default {
  name: 'ScanOrder',
  components: {DetailOrder, UpdateOrder},
  computed: {
    ...mapGetters({
      countries: 'country/all',
    }),
  },
  data() {
    return {
      batchReceive: false,
      batchFile: null,
      isActiveEdit: false,
      showOrderInfo: false,
      showPackageInfo: false,
      dialogShipExpress: false,
      dialogUpdateOrder: false,
      search: {
        code: '',
        status: 2,
        note: '',
      },
      order: {},
    }
  },
  methods: {
    ...mapActions({
      changeOrderStatus: 'order/changeOrderStatus',
      changeOrderStatusBatch: 'order/changeOrderStatusBatch',
      getOrder: 'order/get'
    }),
    async updateOrder() {
      let foundOrder = await this.findOrderByCodeOrTrackingNumber();
      if (foundOrder.length) {
        this.order = foundOrder[0];
        let changeStatusResult = await this.changeOrderStatus({ids: [this.order.id], status: this.CONSTANTS.ORDER_STATUS.IN_WAREHOUSE_WAIT_PACKAGE});
        if (changeStatusResult.data && changeStatusResult.data.error === false) {
          this.order.status = this.CONSTANTS.ORDER_STATUS.IN_WAREHOUSE_WAIT_PACKAGE;
          this.showOrderInfo = true;
        } else {
          this.noticeError(changeStatusResult.data.message)
        }
      } else {
        this.noticeError('Order not found')
      }
    },
    async findOrderByCodeOrTrackingNumber() {
      let foundOrder = await this.getOrder({
        searchFields: {
          code: {
            value: this.search.code,
            type: '='
          },
        },
        searchWith: 'customer;orderItems;bigContainer;orderFees;payments',
      });
      
      foundOrder = foundOrder.data ? foundOrder.data.data : [];
      if (foundOrder.length >= 1) return foundOrder;
      foundOrder = await this.getOrder({
        searchFields: {
          tracking_number: {
            value: this.search.code,
            type: '='
          },
        },
        searchWith: 'customer;orderItems;bigContainer;orderFees;payments',
      });

      foundOrder = foundOrder.data ? foundOrder.data.data : [];
      return foundOrder;
    },
    onOrderUpdated(res) {
      this.updateOrder();
    },
    onClickEditOrder() {
      this.dialogUpdateOrder = true
    },
    onClickDetail() {
      // this.order = res;
    },
    async updateOrderBatch() {
      let data = new FormData();
      data.append('batch_file', this.batchFile);
      data.append('status', this.CONSTANTS.ORDER_STATUS.IN_WAREHOUSE_WAIT_PACKAGE);
      this.changeOrderStatusBatch(data).then((res) => {
        this.batchFile = null;
        console.log(res);
      });
    }
  },
  created() {
  },
}
</script>
