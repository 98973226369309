<template>
  <div>
    <div class="title-col title-order font-weight-bold color-main my-4">
      Phí vận chuyển
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            style="font-size: 2.25rem; margin-left: 1rem"
            v-on="on"
            @click="addOrderFee"
            color="success"
            >mdi-plus
          </v-icon>
        </template>
        <span>Ấn vào đây để thêm phí vận chuyển</span>
      </v-tooltip>
    </div>
    <div v-if="dataSync.length > 0" class="py-3 px-3 table-order-fees">
      <v-row class="v-data-table v-card v-sheet v-sheet--outlined theme--light">
        <v-col cols="12" class="v-data-table__wrapper">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Fee name</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(orderFee, idx) in dataSync" :key="idx">
                <td>{{ idx + 1 }}</td>
                <td>
                  <v-text-field
                    v-model="orderFee.fee_name"
                    dense
                    label="Tên chi phí"
                    outlined
                    :disabled="!orderFee.not_save"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="orderFee.fee_amount"
                    dense
                    placeholder="đơn vị USD"
                    label="Giá"
                    outlined
                    type="number"
                    :disabled="!orderFee.not_save"
                  ></v-text-field>
                </td>
                <td>
                  <div v-if="orderFee.not_save" style="display: flex; justify-content: space-between">
                    <v-btn
                      class="btn-package closed"
                      icon
                      color="success"
                      @click="removeOrderFee(idx)"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon light v-bind="attrs" v-on="on"
                            >mdi-close</v-icon
                          >
                        </template>
                        <span>Xoá chi phí</span>
                      </v-tooltip>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="btn-package check" icon color="success" @click="onClickSaveOrderFee(orderFee, idx)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon light v-bind="attrs" v-on="on"
                            >mdi-check</v-icon
                          >
                        </template>
                        <span>Thêm chi phí</span>
                      </v-tooltip>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: "UpdateOrderFee",
  props: {
    order_fees: {
      type: Array,
    },
    orderId: {
      type: [Number, String],
    }
  },
  data: () => ({
    item: {},
  }),
  computed: {
    dataSync: {
      get: function () {
        return this.order_fees;
      },
      set: function (newValue) {
        this.$emit("order_fees", newValue);
      },
    },
  },
  methods: {
    ...mapActions({
      createOrderFee: 'order_fee/create',
    }),
    removeOrderFee(e) {
      if (this.dataSync.length > 0) {
        this.dataSync.splice(e, 1)
      }
      this.$forceUpdate();
    },
    addOrderFee() {
      if (!this.dataSync) this.dataSync = [];
      this.dataSync.push({
        fee_name: "",
        fee_amount: 0,
        not_save: true,
      });
      this.$forceUpdate();
    },
    onClickSaveOrderFee(orderFee, index) {
      if(orderFee.fee_name .trim().length === 0) {
        this.noticeError('Fee name required!');
        return;
      }
      this.createOrderFee({...orderFee, order_id: this.orderId}).then(() => {
        this.dataSync[index]['not_save'] = false;
        this.noticeSuccess('Order fee created!');
      });
    }
  },
  watch: {
    orderFee: {
      handler() {
        if (this.orderFee) this.item = this.orderFee;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
.closed {
  /* position: absolute;
  right: -1rem; */
  background: var(--v-error-lighten1);
  color: #fff !important;
  width: 2rem;
  height: 2rem;
}
.check {
  background: #4caf4f;
  color: #fff !important;
  width: 2rem;
  height: 2rem;
}
</style>
<style lang="scss">
.table-order-fees {
  .v-text-field__details {
    display: none;
  }
}
</style>
